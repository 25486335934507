<template>
    <div class="page">
        <div class="content">
            <Form :model="formItem" label-position="right" :label-width="130" style="width: 400px;min-height: 70vh;">
                <FormItem label="头像">
                    <div style="display:flex;align-items:center;width: 30vw;">
                        <Upload 
                            ref="upload"
                            :show-upload-list="false"
                            :format="['jpg', 'jpeg', 'png', 'gif']"
                            :max-size="2048"
                            :on-format-error="handleFormatError"
                            :on-success="imgSuccess"
                            :on-progress="handleProgress"
                            :action="uploadImg"
                            :headers="headers"
                            >
                            <div v-if="!formItem.img_id" class="box">
                                <img class="box-icon" src="@/assets/add_img.png">
                            </div>
                            <div v-else style="position: relative;">
                                <img class="box-img" :src="formItem.img_path">
                                <img class="box-del" src="@/assets/del.png" @click.stop="imgDel">
                            </div>
                        </Upload>
                    </div>
                </FormItem>
                <FormItem label="昵称">
                    <Input v-model="formItem.name" type="text" placeholder="请输入昵称" disabled></Input>
                </FormItem>
                <FormItem label="职位">
                    <Input v-model="formItem.job" type="text" placeholder="请输入职位"></Input>
                </FormItem>
                <FormItem label="微信号">
                    <Input v-model="formItem.wx_number" type="text" placeholder="请输入微信号"></Input>
                </FormItem>
                <FormItem label="手机号">
                    <Input v-model="formItem.phone" type="text" placeholder="请输入手机号"></Input>
                </FormItem>
                <FormItem label="线下提现二维码">
                    <div style="display:flex;align-items:center;width: 30vw;">
                        <Upload 
                            ref="upload"
                            :show-upload-list="false"
                            :format="['jpg', 'jpeg', 'png', 'gif']"
                            :max-size="2048"
                            :on-format-error="handleFormatError"
                            :on-success="txImgSuccess"
                            :on-progress="handleProgress"
                            :action="uploadImg"
                            :headers="headers"
                            >
                            <div v-if="!formItem.tx_img_id" class="box">
                                <img class="box-icon" src="@/assets/add_img.png">
                            </div>
                            <div v-else style="position: relative;">
                                <img class="box-img" :src="formItem.tx_img_path">
                                <img class="box-del" src="@/assets/del.png" @click.stop="txImgDel">
                            </div>
                        </Upload>
                        <div class="upload-txt">用于线下提现使用,务必上次正确收款的二维码图片,如上传错误二维码,会导致提现失败!</div>
                    </div>
                </FormItem>
            </Form>
            <div class="foot">
                <Button type="primary" style="background: #296FE1;" @click="addBtn">确认</Button>
            </div>
        </div>
    </div>
</template>

<script>
import { getCookies } from "@/utils/helper";
import config from "@/config/index";
import { user_info, update_info } from "@/api/index";
export default {
    data() {
        return {
            uploadImg: config.apiUrl + "/pc/other/uploads",
            headers: {"authori-zation-jobs-pc": getCookies("authori-zation-jobs-pc")},
            formItem: {
                wx_number: '',
                phone: '',
                tx_img_id: '',
                img_id: '',
                job: '',
                name: '',
                tx_img_path: '',
                img_path: '',
            },
        };
    },
    mounted(){
        this.user_info()
    },
    methods: {
        user_info(){
            user_info().then(res => {
                this.formItem = res.data.info
                if(res.data.info.tximg[0]){
                    this.formItem.tx_img_path = res.data.info.tximg[0].path
                }
                if(res.data.info.img[0]){
                    this.formItem.img_path = res.data.info.img[0].path
                }
            })
        },
        addBtn() {
            // const phoneReg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
            // if(!phoneReg.test(this.formItem.phone)){
            //     this.$Message.error('请输入正确的手机号码');
            //     return
            // }
            let data = {
                wx_number: this.formItem.wx_number,
                phone: this.formItem.phone,
                tx_img_id: this.formItem.tx_img_id,
                img_id: this.formItem.img_id,
                job: this.formItem.job,
            }
            update_info(data).then((res) => {
                this.user_info()
                this.$Message.success(res.msg);
            })
            .catch((err) => {
                this.$Message.error(err.msg);
            });
        },
        imgDel(){
            this.formItem.img_id = ''
            this.formItem.img_path = ''
        },
        txImgDel(){
            this.formItem.tx_img_id = ''
            this.formItem.tx_img_path = ''
        },
        //文件上传类型错误
        handleFormatError() {
            this.$Message.warning("暂不支持上传此格式");
        },
        // 文件上传时
        handleProgress() {
            const msg = this.$Message.loading({
                content: "上传中...",
                duration: 0,
            });
            setTimeout(msg, 3000);
        },
        // 上传成功
        txImgSuccess(e) {
            this.formItem.tx_img_id = e.data.id
            this.formItem.tx_img_path = e.data.path
            this.$Message.destroy();
            this.$Message.success(e.msg);
        },
        imgSuccess(e){
            this.formItem.img_id = e.data.id
            this.formItem.img_path = e.data.path
            this.$Message.destroy();
            this.$Message.success(e.msg);
        },
    },
};
</script>

<style lang="scss" scoped>
.upload-txt{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #FF0000;
    margin-left: 20px;
    line-height: 20px;
}
.page{
    width: 100%;
    height: 100%;
    background-color: #E9F0F2;
    padding: 20px;
}
.content{
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    padding: 40px;
}
.foot{
    width: 100%;
    padding: 10px 0;
    border-top: 1px solid #ccc;
    margin: 10px 0;
    text-align: center;
}
.box{
    width: 80px;
    height: 80px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #B8C4D1;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.box-icon{
    width: 24px;
    height: 24px;
}
.box-img{
    width: 80px;
    height: 80px;
    border-radius: 4px;
}
.box-del{
    width: 18px;
    height: 18px;
    position: absolute;
    top: -9px;
    right: -9px;
    cursor: pointer;
}
</style>